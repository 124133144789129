const uploadReal = document.querySelector('.upload-real input');

if (uploadReal) {
    const uploadFake = document.querySelector('.upload');
    
    console.log(uploadReal)
    
    uploadFake.addEventListener('click', (e) => {
        uploadReal.click();
    });
    
    uploadReal.addEventListener('change', () => {
        if (uploadReal.files.length > 0) {
            const fileName = uploadReal.files[0].name;
            uploadFake.textContent = fileName;
        } else {
            uploadFake.textContent = 'Upload a file';
        }
    });
}